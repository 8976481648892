import React, { useEffect, useState } from "react";

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";

import { 
    Typography, 
    Input, 
    Checkbox, 
    Button, 
    Dialog,
    DialogFooter,
} from "@material-tailwind/react";

import {
  Radio,
} from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
import AuthUser from '../helpers/AuthUser';
import {fncalculationswithgiftcard} from '../helpers/checkoutCalculations';
import {TherapistOption} from '../helpers/appointmentOptions';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'react-calendar/dist/Calendar.css'
import Calendar from 'react-calendar';
import BookingConfirmation from './BookingConfirmation';
import Loading from '../Components/Loading';
import toast, { Toaster } from 'react-hot-toast';
export default function CheckoutForm(props) {
    const {acfbterms} = props;
    const navigate = useNavigate();
    const {http,user} = AuthUser();
    const [loading, setLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    
    const [error,setError] = useState(false);
    const [done,setDone] = useState(false);
    const [message, setMessage] = useState(null);

    const [Errormessage, setErrorMessage] = useState(null);
    const [PaymentErrormessage, setPaymentErrormessage] = useState(null);
    const [orderConfirmation, setOrderConfirmation] = useState(null);
    const [bookingId, setBookingId] = useState(null);

    const [Datemessage, setDayMessage] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [open, setOpen] = useState(false);
    const [Therapist, setTherapist] = useState(false)
    const [MassageType, setMassageType] = useState(null)
    const [massagetypelabel, setMassageTypeLabel] = useState(null)
    const [SelectedService, SetSelectedService] = useState([])
    const [topping, setTopping] = useState(false)
    const [services, setService] = useState([])
    const [appointments, setAppointments] = useState([]);
    const [SpecifcDateAvilability, setSpecifcDateAppointment] = useState([]);
    const [date, setDate] = useState(new Date())
    const [showTime, setShowTime] = useState(true);
    const [SelectedAppointmentDate, setSelectedAppointmentDate] = useState();
    const [SelectedAppointmentTime, setSelectedAppointmentTime] = useState(true);
    const [Voucher, SetVoucher] = useState(null);
    const [balance, SetCurrentBalance] = useState(null);
    const [pendingBalance, setPendingBalance] = useState(0);
    const [cancelledFees, setcancelledFees] = useState(0);


    const CARD_ELEMENT_OPTIONS = {
      style: {
        base: {
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      },
  };

  const handleOpen = () => setOpen(!open);
  const anotherBooking = () => {
      setOpen(!open);
      window.location.reload();
  }

  const getProducts = async() => {
        await http.get(`auth/services/${MassageType}`).then((resp)=>{
          const price = resp.data.data.promo_price?resp.data.data.promo_price:resp.data.data.price;
          SetSelectedService(price);
          setErrorMessage('');
        }).catch((error) => {
          setErrorMessage(error.response.data.message);
        });
    }

    const fetchPendingBalance = async () => {
      try {
        const  response = await http.get('/user/pending-balance');
        const  pendingBalance  = response.data;
         setPendingBalance(pendingBalance.balance);
         setcancelledFees(pendingBalance.cancelledFees);
      } catch (error) {
        console.log(error.response);
      }
    };
    useEffect(()=>{
      if(MassageType){
        getProducts()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[MassageType])

    useEffect(() => {
      GetServiceList()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

    const GetServiceList = () => {
        http.get('auth/services').then((response)=>{
          setService(response.data.data);
        }).catch(error => {
          console.log(error.response)
      });  
    }

    const GetAvilableStudentList = (params) => {
        setLoading(true);
        http.post('/next_avaliable_bookings',params)
        .then((response)=>{
          if (response?.data?.data) {
            const appointmentsData  = response.data.data;
            const appointmentsArray = Object.values(appointmentsData);
            setAppointments(appointmentsArray);
            setMessage('');
            setLoading(false);
          } else {
            setMessage('No appointments data found.');
            setAppointments([]);
            setLoading(false);
          }
        }).catch(error => {
            setMessage(error.response?.data?.message || 'An error occurred.');
            setAppointments([]);
        });
    }

    const GetAvilableStudentDate = (params) => {
        setLoading(true);
        http.post('/avaliable_bookings',params).then((response)=>{
          if (response?.data) {
            const appointmentsData  = response.data;
            const appointmentsArray = Object.values(appointmentsData);
            setSpecifcDateAppointment(appointmentsArray);
            setDayMessage('');
            setLoading(false);
          }else {
            setDayMessage('Sorry,no session available for this date.');
            setSpecifcDateAppointment([]);
            setLoading(false);
          }
        }).catch(error => {
          setLoading(false);
          setDayMessage(error.response?.data?.message || 'An error occurred.');
          setSpecifcDateAppointment([]);
        });
    }

    const onOptionChange = e => {
        let parms = {
            massage_type: e.target.value,
            provider_preference: Therapist,
        };
        setFieldValue('massage_type',e.target.value);
        setFieldValue('appointment_id',null);
        setIsActive(false);
        setMassageType(e.target.value);
        setMassageTypeLabel(e.target.getAttribute('label'));
        setTopping(e.target.value)
        if(parms.provider_preference){
            GetAvilableStudentList(parms);
            GetAvilableStudentDate(parms);
        }
   }

    const TherapistOptionChange = e => {
        setTherapist(e.target.value)
        setFieldValue('provider_preference',e.target.value)
        setFieldValue('appointment_id',null);
        setIsActive(false);
        let parms = {
            massage_type: topping,
            provider_preference: e.target.value,
        };
        if(parms.massage_type){
            GetAvilableStudentList(parms);
            GetAvilableStudentDate(parms);
        }
    }

    const handleSearch = async (event) => {
        SetVoucher(null);
        const code1 = getFieldProps('gift_card').value;
        if(code1){
          let parms = {
            code: code1,
          };
        await http.post('/redeemVoucher',parms).then((response)=>{
            SetVoucher(response.data);
            SetCurrentBalance(response.data.balance);
        }).catch(error => {
           SetVoucher('');
           setMessage(error.response.data.message);
        });
        }
    }

    const handleSearchAvilability = (selectedDate) => {
        setShowTime(true)
    }
    const getSelectedAppointmentdata = async(AppointmentID) => {
      await http.get(`auth/appointment-service/${AppointmentID}`).then((resp)=>{
        SetSelectedService(resp.data.serviceAmount);
        setErrorMessage('');
      }).catch((error) => {
        setErrorMessage('test');
      });
  }
    const handleSort = (current,date,time) =>{
      console.log('currentid',current,'date',date,'time',time)
        setIsActive(current);
        setFieldValue('appointment_id',current);
        setSelectedAppointmentDate(date);
        setSelectedAppointmentTime(time);
        getSelectedAppointmentdata(current)
        if(current){
          fetchPendingBalance();
        }

    }
    
    const handleDateChange = (selectedDate) => {
        setDate(selectedDate);
        const selecteddateFormat = new Intl.DateTimeFormat('en-US').format(selectedDate)
        let parms = {
            massage_type: topping,
            provider_preference: Therapist,
            date: selecteddateFormat,
          };
        if(parms.massage_type && parms.provider_preference){
            GetAvilableStudentDate(parms);
        }
    }

  /**
   * Calculation based on selected service.
   *
   * @return \Illuminate\Http\Response
   */
  const _fncalculationsresults   =  () => {
        let ServiceAmount        = parseInt(SelectedService?SelectedService:'0.00');
        let GiftCardBalance      = parseInt(balance?balance:'0.00');
        let AppliedCredit        = parseInt(pendingBalance?pendingBalance:'0.00');
        let Cancellation_Fee     = parseInt(cancelledFees?cancelledFees:'0.00');
        let RemainingGiftamount  = '0.00';
        let RemainingApplCredit  = '0.00';
        let UsedGiftamount       = '0.00';
        let TotalServicePrice    = '0.00';
        let TotalAmountService   = '0.00';
        let SelectedServicePrice = ServiceAmount;
        return fncalculationswithgiftcard(SelectedServicePrice,Cancellation_Fee, AppliedCredit, GiftCardBalance,RemainingGiftamount,TotalServicePrice,UsedGiftamount,RemainingApplCredit,TotalAmountService);
    };

   const results = _fncalculationsresults();
    // console.log('results',results)
   /**
     * Run checkout api after form submission.
     *
     * @return \Illuminate\Http\Response
     */

   const makePaymentRequest = async (allformData)=>{
       setIsLoading(true)
        await http.post('orderPost',JSON.stringify(allformData, null, 2)).then((response)=>{
            if(response.data.status === 'APBooked' || response.data.status == 'APBooked'){
                toast.error(response.data.message);
                setError(false); 
                setDone(true); 
                setIsLoading(false);
                setTimeout(() => {
                  window.location.reload();
                }, 3000);
              }else{
                setOrderConfirmation(response.data.data);
                setBookingId(response.data.data.appointment_id); 
                setTimeout(() => {
                  setError(false); 
                  setDone(true); 
                  setIsLoading(false);
                  const orderData = { 
                    orderId: response.data.data.appointment_id 
                  };
                  navigate('/booking-confirmation', { 
                    state: { 
                      orderData 
                    } 
                  });
                }, 1000);
              }
             }).catch(error => {
              setError(true)
              setDone(false); 
              setPaymentErrormessage(error.response.data.message);
              navigate('/make-booking');
        });
    }


    const handleDeleteCard = (event, userId) => {
        event.preventDefault();
        if (window.confirm("Are you sure you want to delete the card details?")) {
            http.put(`auth/user/savedcard/${userId}`)
            .then((response) => {
                toast.success(response.data.message);
                // navigate("/admin/users");
            })
            .catch((error) => {
                if (error?.response) {
                    toast.error(error?.response?.data.message);
                } else {
                    toast.error('An error occurred.');
                }
            });
        } 
    }
   /**
     * Form handle submit events.
     *
     * return response
    */
  const { handleSubmit,errors, touched, getFieldProps, setFieldValue } = useFormik({
      initialValues: {
          provider_preference:'',
          appointment_id:'',
          massage_type:'',
          gift_card:'',
          terms_condition:false,
      },
      validationSchema: Yup.object({
          massage_type: Yup.string().required('Please Select Service'),
          provider_preference: Yup.string().required('Please select a gender.'),
          appointment_id: Yup.string().required('Please Select a time'),
          terms_condition:Yup.boolean().oneOf([true], 'You must accept the Terms and Conditions.'),
      }),

      onSubmit: async (values) => {

          let cartTotal         = parseFloat(results.TotalServicePrice).toFixed(2);
          let TotalServicePrice = parseFloat(results.TotalAmountService).toFixed(2);
          let voucheramount     = parseFloat(results.UsedGiftamount).toFixed(2);
          let applied_credit    = parseFloat(results.AppliedCredit).toFixed(2);
          let Cancellation_Fees = parseFloat(results.Cancellation_Fee).toFixed(2);
          setLoading(true);
          if (parseFloat(cartTotal) <= 0 || parseFloat(cartTotal) === 0.00) {
              const allFormData = {
                  ...values,
                  amount:cartTotal,
                  totalserviceprice:TotalServicePrice,
                  giftcard:voucheramount,
                  applied_credit:applied_credit,
                  Cancellation_Fees:Cancellation_Fees,
                  paymentMethod:'',
                  stripeToken:'',
              }
              await makePaymentRequest(allFormData);
              setLoading(false);
              return;
          }

          if (!stripe || (!elements && cartTotal > 0)) {
              setLoading(false);
              return;
          }
          let card = null;
          let result = null;
          let paymentMethod = null;
          
          card = elements.getElement(CardNumberElement);
          paymentMethod =  await stripe.createPaymentMethod({
              type: 'card',
              card: card,
          });
          result = await stripe.createToken(card);

          if (result.error) {
              setPaymentErrormessage(result.error.message);
              setLoading(false);
          }
          const allFormData = {
              ...values,
              amount:cartTotal,
              totalserviceprice:TotalServicePrice,
              giftcard:voucheramount,
              applied_credit:applied_credit,
              Cancellation_Fees:Cancellation_Fees,
              paymentMethod:paymentMethod.paymentMethod.id,
              stripeToken:result.token.id,
          }
          await makePaymentRequest(allFormData);
          setLoading(false);
      },
  });


  if(isLoading)  return  <div className="text-center"> <h1 className="text-success mt-5">Wait, Processing Your Payment</h1> </div>

  if(done){ 
    return(  
      <BookingConfirmation data={orderConfirmation} bookingId={bookingId} />
    )
  }
  
  return (<>
        { loading && <Loading /> }
        {Errormessage && <p className="error-message red">{Errormessage}</p>}

        { !error && !done && !isLoading &&
          <form className="pt-10 pb-10 make-booking-form" id="payment-form" onSubmit={handleSubmit}>

            <div className="mb-8 md:mb-14">
                <div className="w-full mb-10">
                    <Typography variant="paragraph" className="font-bold text-base mb-2">1) What type of treatment would you like?</Typography>
                    <Typography variant="paragraph" className="font-normal text-base pl-5">There are three types of treatments available at ACFB and all sessions are 1hr in duration.</Typography>
                    <Typography variant="paragraph" className="font-normal text-sm italic pl-5">Sessions include, interview, assessment & treatment.</Typography>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 flex-wrap lg:flex lg:flex-nowrap gap-y-6 gap-x-6">
                    {services.map((item, index) => (
                        <div className="massage-type-group w-full lg:w-4/12 drop-shadow-md bg-white rounded-lg overflow-hidden relative">
                              <input type="radio"  
                                  {...getFieldProps('massage_type')}
                                  id={`massage_type_` + index} 
                                  name="massage_type"  
                                  key={index}
                                  label={item.title}
                                  className="input-checkbox"
                                  value={item.id}
                                  amount={item.price}
                                  onChange={onOptionChange}
                               />
                                <div class="massage-box p-3 bg-white rounded-lg overflow-hidden">
                                    <img className="rounded-lg w-full mb-4" src={item.image} alt={item.title} />
                                    <Typography variant="h5" className="text-base font-semibold mb-5">{item.title}</Typography>
                                    <div className="flex mb-4 items-baseline">
                                        {item.promo_price.length > 1 &&
                                          <Typography variant="h4" className="text-red-500 mr-2">{item.promo_price}</Typography>
                                        }
                                        <Typography variant="h4" className={ item.promo_price.length > 1 ? 'line-through font-normal opacity-40 ':'' }>${item.price}</Typography>
                                    </div>
                              </div>
                        </div>
                    ))}
                </div>
                {(touched.massage_type && errors.massage_type) && <span className="error">{errors.massage_type}</span>}
            </div>

            <div className={` ${ MassageType > 0 ? "enabled" : "disabled" } mb-8 md:mb-14`}>
                <div className="w-full mb-4">
                    <Typography variant="paragraph" className="font-bold text-base mb-2">2) Do you have a preference of your Therapist?</Typography>
                </div>

                <div className="sm:flex gap-10 mb-6">
                  {TherapistOption.map((option, index) => ( 
                      <Radio
                        id={`provider_preference_` + index}
                        name="provider_preference" 
                        label={option.label} 
                        value={option.value}
                        className="booking-radio"
                        onChange= {TherapistOptionChange}
                      />
                    ))}
                </div>

                {(touched.provider_preference && errors.provider_preference) && <span className="error">{errors.provider_preference}</span>}
            </div>

            <div className={` ${ MassageType > 0 ? "enabled" : "disabled" } mb-14`}>
                
                <div className="w-full mb-4">
                    <Typography variant="paragraph" className="font-bold text-base mb-4">3) Choose next available session (Next 10 sessions displayed only)</Typography>
                </div>
                
                <div className="grid grid-cols-2 md:grid-cols-5 gap-4 mb-6 times-grid-outer">

                    {message && <p className="no-session red">{message}</p>}

                    {appointments.map((appointment) => (
                          <a key={appointment.appointment_id}  onClick={(e) => { handleSort(appointment.appointment_id,appointment.appointment_date,appointment.time);  }}
                          data-time={appointment.time} data-date={appointment.appointment_date} 
                          className={`available_time bg-white rounded-md p-4 md:p-6 text-center border-darkgrey1 ${appointment.appointment_id === isActive ? 'active' : ''}`}
                            href="javascript:void(0)" data-appointment={appointment.appointment_id} 
                            data-amount={appointment.appointment_id}>
                                <div className="cls-date">{appointment.appointment_date}</div>
                                <div className="appointment-time">{appointment.time}</div>
                                 <div class="appointment-available">{appointment.studentName}</div>
                                <div class="appointment-available hidden">{appointment.available_count} available</div>
                          </a> 
                    ))}
                </div>
            </div>
            <div className="mb-7 md:mb-10">
                <div className="w-full mb-10">
                    <Typography variant="paragraph" className="font-bold text-base mb-2">OR Select Your Preferred Date & Time</Typography>
                </div>
                
                <div className="grid grid-cols-1 md:flex flex-wrap mb-6">
                    <div className="w-full mb-6 md:mb-0 md:w-5/12">
                        <Calendar onChange={handleDateChange}  value={date} onClick={handleSearchAvilability} onClickDay={handleSearchAvilability} showNeighboringMonth={false} />
                    </div>
                    <div className="w-full md:w-7/12 pl-6">
                      
                      {Datemessage && <p className="no-session red">{Datemessage}</p>}
                      {showTime === true &&
                        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-6 times-grid-outer"> 
                            {SpecifcDateAvilability.map(( appointment ) => ( 
                                <a key={appointment.appointment_id} href="javascript:void(0)"  onClick={(e) => {  handleSort(appointment.appointment_id,appointment.appointment_date,appointment.time); }}
                                data-time={appointment.time} data-date={appointment.appointment_date}  className={`available_time bg-white rounded-md p-4 md:p-6 text-center border-darkgrey1 ${appointment.appointment_id === isActive ? 'active' : ''}`}>
                                    <div class="cls-date" data-date={appointment.appointment_date}>{appointment.appointment_date}</div>
                                    <div data-time={appointment.time}>{appointment.time}</div>
                                    <div class="available ">{appointment.studentName} </div>
                                    <div class="available hidden">{appointment.available_count} available</div>
                                </a> 
                                ))}
                          </div>
                        }
                   </div>
                </div>
            </div>

            <hr className="mt-6 md:mt-14 mb-10" style={{ borderColor: '#CCCCCC' }} />
            {acfbterms&&
            <Typography variant="paragraph" className="text-sm text-black font-medium mb-10">{acfbterms.acfb_terms_content}</Typography>
            }
            <div className={` ${ isActive ? "enabled" : "disabled" } grid grid-cols-1 md:grid-cols-2 gap-x-10`}>
              <div className="w-full mb-6 md:mb-0">
                  
                  <div className="bg-white border border-greycolor8 rounded-lg px-6 py-6 md:px-8 md:py-8">
                      <Typography variant="h2" className="text-lg text-black font-bold mb-10">Payment Summary</Typography>  

                      <div className="flex mb-6 justify-between">
                        <div>
                          <Typography variant="h5" className="text-sm text-black font-bold mb-0">Price</Typography>
                          <Typography variant="paragraph" className="text-sm text-black font-normal mb-0">Pay in full</Typography>
                        </div>
                        {parseFloat(SelectedService) >= 0 &&
                          <div> 
                            <Typography variant="paragraph" className="text-sm text-black font-normal mb-0">${parseFloat(SelectedService).toFixed(2)}</Typography>
                          </div>
                        }
                      </div>

                     
                      <div className="flex mb-6 justify-between">
                        <div>
                          <Typography variant="h5" className="text-sm text-black font-bold mb-0">Additional Charges</Typography>
                          <Typography variant="paragraph" className="text-sm text-black font-normal mb-0">Cancellation Fee from previous appointment</Typography>
                        </div>
                        <div>
                          <Typography variant="paragraph" className="text-sm text-black font-normal mb-0">${parseFloat(results.Cancellation_Fee).toFixed(2)}</Typography>
                        </div>
                      </div>
                        
                      <div className="flex mb-6 justify-between">
                        <div>
                          <Typography variant="h5" className="text-sm text-black font-bold mb-0">Applied Gift Card Amount</Typography>
                          {Voucher && 
                          <Typography variant="paragraph" className="text-sm text-black font-normal mb-0">
                             {Voucher.code}  (Remaining ${results.RemainingGiftamount?parseFloat(results.RemainingGiftamount).toFixed(2):'0.00'})
                          </Typography>
                          }
                        </div>

                        <div>
                            <Typography variant="paragraph" className="text-sm text-black font-normal mb-0">
                               - ${results.UsedGiftamount?parseFloat(results.UsedGiftamount).toFixed(2):"0.00"}
                            </Typography>
                        </div>
                      </div>

                      <div className="flex mb-6 justify-between">
                          <div>
                              <Typography variant="h5" className="text-sm text-black font-bold mb-0">Applied Credit</Typography>
                          </div>
                          <div>
                              <Typography variant="paragraph" className="text-sm text-black font-normal mb-0">-${ parseFloat(results.AppliedCredit) > 0 ? parseFloat(results.AppliedCredit).toFixed(2) : 0.00 }</Typography>
                          </div>
                      </div>

                       <div className="flex mb-6 justify-between">
                          <div>
                            <Typography variant="h5" className="text-sm text-black font-bold mb-0">Remaining Balance Payment Method</Typography>
                          </div>
                          <div>
                            <Typography variant="paragraph" className="text-sm text-black font-normal mb-0">Credit Card</Typography>
                          </div>
                      </div>

                      <hr className="mt-4 mb-4" style={{ borderColor: '#CCCCCC' }} />

                      <div className="flex justify-between mb-4">
                        <div>
                          <Typography variant="h5" className="text-sm text-black font-bold mb-0">Total Amount</Typography>
                        </div>
                        <div>
                        <Typography variant="h5" className="text-sm text-black font-bold mb-0">
                              ${results.TotalAmountService > 0 ? parseFloat(results.TotalAmountService).toFixed(2) :'0.00' }
                           </Typography>  
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div>
                          <Typography variant="h5" className="text-sm text-black font-bold mb-0">Total Amount Due Now:</Typography>
                        </div>
                        <div>
                        <Typography variant="h5" className="text-sm text-black font-bold mb-0">
                                ${results.TotalServicePrice > 0 ? parseFloat(results.TotalServicePrice).toFixed(2) :'0.00' }
                           </Typography>  
                        </div>
                      </div>

                  </div>

              </div>
             {results.TotalServicePrice > 0 &&
              <div className="w-full">
                 <Typography variant="h3" className="text-lg text-black font-bold mb-10">Payment Method *</Typography>      
                      <div className="flex flex-col checkbox-row mb-4">
                        <div className="flex items-center">
                          <label for="Credit/Debit">Credit/Debit Card</label>
                        </div>
                         <Typography variant="p" className="text-sm leading-tight text-black" style={{ fontSize: '0.8rem', lineHeight: '1.3rem' }}>IMPORTANT: If 'Save with Link' is selected, your card details will be autofilled by Link by Stripe (not ACFB). 
                         <a href="https://link.com/au" className="text-blue-900 font-medium" target="_blank">Manage your Link account here. </a>
                         <a onClick={(event) => { handleDeleteCard(event,user.id)}} className="text-blue-900 font-medium">OR click here to remove saved Card.</a>
                         </Typography>
                        <div className="flex flex-col">
                          <div className="toggle-form">
                              <CardNumberElement
                              options={CARD_ELEMENT_OPTIONS}
                              />
                              <CardExpiryElement
                              options={CARD_ELEMENT_OPTIONS}
                              />
                              <CardCvcElement
                              options={CARD_ELEMENT_OPTIONS}
                              />
                            </div>
                        </div>
                         {PaymentErrormessage && <div id="payment-message">{PaymentErrormessage}</div>}
                    </div>
               
                    <div className="flex checkbox-row">
                      <div className="flex flex-1 flex-col w-full">
                          <label for="">Gift Card</label>
                          <div className="gift-card">
                             <Input
                              type="text"
                              variant="static"
                              fullwidth="true"
                              placeholder="Add Gift Card"
                              id="Gift"
                              {...getFieldProps('gift_card')}
                              className="border-black placeholder-shown:border-black"
                            />
                          
                            {Voucher && Voucher.status === 'success' ? (
                              <span className="alert alert-success">{Voucher.message}</span>  
                             ):(
                              <Button onClick={handleSearch} className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block 
                                  text-white capitalize text-sm px-8 py-3">Apply</Button> 
                             )}
                            {Voucher && Voucher.status === 'error' ? (
                              <div className="te">
                                <span className="alert alert-danger">{Voucher.message}</span>  
                              </div>
                           ):('')}
                        </div>
                      </div>
                    </div>
              </div>
              }  
           </div>
         


           <div className="w-full mt-5">
              {acfbterms.acfb_termsconditions&&
                <div className="h-96 mt-6 overflow-auto terms-content">
                  <div dangerouslySetInnerHTML={{ __html: acfbterms.acfb_termsconditions }}></div>
                </div>
              }
                <hr className="mt-6 mb-8" style={{ borderColor: '#707070' }} />
              <Checkbox
                {...getFieldProps('terms_condition')}
                label={
                  <Typography color="blue-gray" className="flex font-medium">By booking this appointment, you are agreeing that you have read and agree to the Terms & Conditions as set out on the first registration/login of this booking system.</Typography>
                }
                containerProps={{
                  className: "-mt-5 p-0 mr-3 terms-condition-checkbox",
                }}        
              />
              {(touched.terms_condition && errors.terms_condition) && <span className="error">{errors.terms_condition}</span>}
           </div>

          <button id="submit" type="submit" className="bg-blue-900 hover:bg-black active:bg-black focus:bg-black rounded-md shadow-none text-white capitalize text-sm px-8 py-3 mt-4"  disabled={loading}>
             {loading ? <div className="spinner-border spinner-border-sm text-light" role="status"></div> : `Confirm Appointment`}
          </button>

            {PaymentErrormessage && <div className="text-danger mt-2">{PaymentErrormessage}</div>}
            {Errormessage && <div className="text-danger mt-2">{Errormessage}</div>}
          </form>
        }

        <Dialog open={open} handler={handleOpen}>
            { error &&
               <div className="text-center pt-16 pb-6"> <h1 className="text-danger mt-5">Your booking failed</h1></div>
            }
            { done &&
                <div className="text-center pt-16 pb-6">
                    <Typography variant="h2" className="text-2xl text-blue-900 font-bold mb-0">Your booking has been confirmed for</Typography>
                    <Typography variant="h2" className="text-2xl text-blue-900 font-bold mb-0">{massagetypelabel}</Typography>
                    <Typography variant="h2" className="text-2xl text-blue-900 font-bold mb-0">Date: {SelectedAppointmentDate}</Typography>
                    <Typography variant="h2" className="text-2xl text-blue-900 font-bold mb-0">Time: {SelectedAppointmentTime}</Typography>
                </div>
            }
            { isLoading &&
               <div className="text-center pt-16 pb-6"> <h1 className="text-success mt-5">Wait, Processing Your Payment</h1> </div>
            }

            <DialogFooter className="block text-center pb-10">
              <Button className="bg-blue-900 hover:bg-black active:bg-black focus:bg-black rounded-md shadow-none text-white capitalize text-sm px-8 py-3" onClick={anotherBooking}>Make Another Booking</Button>
            </DialogFooter>
        </Dialog>
    </>
  );
}